.ptp--wrapper {
    position: absolute;
    right: 15px;
    top: 15px;
}
.ptp {
    display: flex;
}
.ptp--label {
    align-items: baseline;
    display: flex;
    font-weight: bold;
}
.ptp--checkbox {
    margin-right: 10px;
}
.ptp--tooltip {
    margin-left: 10px;
    position: relative;
}
.ptp--tooltip__toggle {
    appearance: none;
    background: transparent;
    border: 0;
    color: $c-link;

    &:hover,
    &:focus {
        color: $c-link-hover;
    }
    &:active {
        color: darken($c-link-hover, 5%);
    }
}
.ptp--icon {
    height: 1em;
    margin-top: 4px;
}
.ptp--tooltip__message {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
    margin-top: 5px;
    padding: 10px 20px 15px;
    position: absolute;
    right: -10px;
    width: 300px;

    &::before {
        background: #fff;
        border-left: 1px solid #d8d8d8;
        border-top: 1px solid #d8d8d8;
        content: '';
        height: 10px;
        position: absolute;
        right: 10px;
        top: 0;
        transform: translateY(-60%) rotate(45deg);
        width: 10px;
    }
}
