/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// GravDept Reset
// ==============================================

html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Prevent tap highlight on iOS/Android
    -webkit-text-size-adjust: 100%; // Prevent automatic scaling on iOS
}

*,
*:before,
*:after {
    box-sizing: inherit; // http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
    margin: 0;
    padding: 0;
    outline: 0;
}

body {
    background: #FFF;
    color: #000;
    line-height: 1;
}

html,
body,
img,
fieldset,
abbr,
acronym {
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

th,
code,
cite,
caption {
    font-weight: normal;
    font-style: normal;
    text-align: left;
}

address {
    font-style: normal;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

img {
    display: block;
    max-width: 100%;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
