/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Units - Breakpoints
// ==============================================

// Define these breakpoints as min-width values.
// Then use "($bp-name - 1px)" to shield the max-width value.

$bp-header: 700px; // When the header swaps from small/large screen UI


// ==============================================
// Units - Color
// ==============================================

// Hex is the percentage difference between 101010 and 202020.
// Used with Sass functions darken() and lighten().
$hex: 6.1%;


// ==============================================
// Units - Spacing
// ==============================================

// Gap is the standard vertical spacing between modules.
$gap: 30px;

// Gutter is the space between grid columns.
$gutter: 16px;

// Liner is the whitespace between the content box and content.
$liner: 16px;

// Trim is the whitespace between the content and viewport edge.
$trim: 16px;


// ==============================================
// Dimensions - Footer
// ==============================================


// ==============================================
// Dimensions - Header
// ==============================================

$m-header-large-height: 70px;
$m-header-small-height: 50px;

$m-header-logo-large-width:  50px;
$m-header-logo-large-height: 50px;

$m-header-logo-small-width:  140px;
$m-header-logo-small-height: 30px;

$m-header-menu-radius: ($radius * 3);


// ==============================================
// Dimensions - Max
// ==============================================

$max-content-width:       1200px;
$max-container-width:     $trim + $max-content-width + $trim;
$max-std-formatted-width: 45em;
