/**
* Threadloom
* https://threadloom.com
*/


// ==============================================
// Firebase Login UI Overrides
// ==============================================

// Replace the "First & last name" label with "Scoring username"
// [todo] this technique isn't accessible
label[for=name] {
    color: transparent;
}

.mdl-textfield--floating-label label[for=name] {
    color: transparent !important;
}

label[for=name]:before {
    content: 'Scoring username';
    color: $c-text;
}


// ==============================================
// Sign In Page
// ==============================================

.icon-100 {
    height: 100px;
    width: 100px
}

.icon-100, .icon-20, .icon-200, .icon-30, .icon-300, .icon-40, .icon-400,
.icon-50, .icon-500, .icon-60, .icon-70, .icon-90 {
    max-width: 100%
}

#logged-out .mdl-shadow--2dp {
    box-shadow: none;
}

#logged-out .mdl-button--primary.mdl-button--primary {
    color: rgb(67, 151, 246);
}

#logged-out .mdl-button.mdl-button--colored {
    color: rgb(67, 151, 246);
}

#logged-out .mdl-button--raised.mdl-button--colored {
    background: rgb(67, 151, 246);
    color: #fff;
    border-radius: 50px;
}

#logged-out .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: rgb(67, 151, 246);
}

#logged-out input[type=text], input[type=password], input[type=email] {
    margin-bottom: inherit;
    background-color: inherit;
    font-size: inherit;
    background: inherit;
    outline: inherit;
    border: inherit;
    border-bottom: 1px solid rgba(0,0,0,.12);
    border-width: 1px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    padding: 5px 0;
}

#logged-out .signin-warm-welcome {
    width: 320px;
    margin: auto;
    text-align: left;
}

.centered {
    margin: auto;
    text-align: center;
}

#tl-signin-logo {
    margin: auto;
}
