/**
* Threadloom
* https://threadloom.com
*/


// ==============================================
// Action Row
// ==============================================

.action-row {
    padding: 10px;
}


// ==============================================
// Button
// ==============================================

a.button {
    box-sizing: unset;
    display: block;
    height: 30px;
    text-align: center;

    &:hover {
        color: #FFF;
    }
}

a.button--inline {
    display: inline-block;
}

.button {
    padding: 0 1em;
    border: 0;
    border-radius: 3px;
    background: #BD2031;
    color: #FFF;
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;

    &:focus {
        box-shadow: 0 0 0 3px #B4DEFA;
    }

    &:focus,
    &:hover {
        background: #4A4E51;
    }

    &:disabled {
        cursor: not-allowed;
        background: #4A4E51;
        opacity: 0.5;
    }
}


// ==============================================
// Card
// ==============================================

.card {
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 3px;
    background: #F0F0F6;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    position: relative;
}

.card > *:last-child {
    margin-bottom: 0;
}

.card h3 {
  margin-top: 1em;
  border-bottom: 1px solid #404040;
}

.card_section {
    padding-bottom: 1em;

    &:last-child {
        padding-bottom: 0;
    }
}

.score-card_section {
    border-bottom: 1px solid $c-border;

    &:last-child {
        border-bottom: 0;
    }
}


// ==============================================
// Control
// ==============================================

.control {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}


// ==============================================
// Field
// ==============================================

.field {
    margin-bottom: 15px;
}

.field > label {
    display: inline-block;
    margin-bottom: 0.5em;
    font-size: $f-size-xs;
    font-weight: bold;
}

.field_hint {
    margin-bottom: 0.75em;
    color: $c-text-subtle;
    font-size: 12px;
}


// ==============================================
// Form
// ==============================================

[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='text'] {
    height: 36px;
    padding: 0 6px;
}

textarea {
    padding: 0 5px;
}

// ----------------------------------------------
// State: Disabled

[type='checkbox']:disabled + label,
[type='radio']:disabled + label {
    color: #999;
}

[type='text']:disabled {
    color: grey;
}


// ==============================================
// Input Select
// ==============================================

// [todo] replace with GravDept Select
.input-select {
    width: 100%;
    height: 36px;
    max-width: 50em;
    font-size: 16px;

    &:focus {
        @include focus;
    }
}


// ==============================================
// Input Text
// ==============================================

.input-text {
    width: 100%;
    max-width: 50em;
    border: 1px solid $c-input-border;
    border-radius: 3px;
    font-size: 16px;

    &:focus {
        @include focus;
    }
}

// ----------------------------------------------
// Modifier: Width

.input-text--s {
    max-width: 100px;
}

.input-text--xs {
    max-width: 30px;
}

// [todo] use a proper modifier
.input-text.short-input-text {
    max-width: 12em;
}


// ==============================================
// Inserted Item
// ==============================================

.inserted-item {
    padding: 0 5px;
    background-color: #ffffe6; // [todo] Use color vars
    border-radius: 5px;
}


// ==============================================
// Link
// ==============================================

.link {
    padding: 0;
    border: 0;
    background: none;
    color: #4397F6; // [todo] Use color vars
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #0B73EA; // [todo] Use color vars
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }

    &:active {
        color: inherit;
    }
}


// ==============================================
// Message
// ==============================================

.message {
    font-weight: bold;
}

// ----------------------------------------------
// Modifier: State

.message--error {
    color: #BD2031; // [todo] Use color vars
}

.message--success {
    color: #E6FFE6; // [todo] Use color vars
}


// ==============================================
// Message Bar
// ==============================================

.message-bar {
    position: fixed;
    left: 25%;
    z-index: 100;
    width: 50%;
    padding: 10px;
    border-radius: 10px;
    color: #FFF;
    font-weight: bold;
    text-align: center;
}

.message-bar--error {
    background: $c-danger;
}

.message-bar--success {
    background: $c-success;
    color: black;
}


// ==============================================
// Score Grid
// ==============================================

.score-grid {
    margin: 0 -10px;

    &:after {
        @include clearfix;
    }
}

.score-grid_item {
    padding: 0 10px;
}

// ----------------------------------------------

@media (min-width: 700px) {

    .score-grid_item {
        float: left;
        width: 50%;
    }

    .score-grid_item:nth-child(odd) {
        clear: left;
    }

}


// ==============================================
// Table
// ==============================================

.table {
    margin-bottom: 1.5em;
}

.table th,
.table td {
    padding-bottom: 0.25em;
    font-size: $f-size-xs;
    line-height: 1.2;
    text-align: left;
    vertical-align: top;
}

.table th {
    padding-right: 2em;
}

// [todo] use utility class in HTML
.head-table {
    margin-top: 15px;
}


// ==============================================
// Text
// ==============================================

.text ol,
.text p,
.text ul {
    max-width: 50rem;
}

.text ol {
    margin-bottom: 1.5em;
    list-style: decimal outside;
}

.text ol li {
    margin-left: 2em;
}

.text ul {
    margin-bottom: 1.5em;
    list-style: circle outside;
}

.text ul li {
    margin-left: 2em;
}


// ==============================================
// Wrap
// ==============================================

.wrap {
    max-width: 1300px;
    margin: 0 auto;
}


// ==============================================
// Utilities
// ==============================================

.align-center {
    text-align: center;
}

.float-right {
    float: right;
}

.hide {
  display: none;
}

// ==============================================
// Email Option
// ==============================================

.email-option {
    padding: 5px 0;
}

.email-option label {
    display: inline-block;
    margin-bottom: 0.5em;
    font-size: $f-size-xs;
    font-weight: bold;
}

// [todo] rewrite as a utility class
.right {
    text-align: right;
}

// [todo] rewrite as a utility class or pattern
.center {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
