/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Brand Colors
// ==============================================

// Identity colors
$c-tl-identity-blue: #1B93E8;
$c-tl-identity-blue-extra-light: #F6F9FC;
$c-tl-identity-blue-shaded: darken($c-tl-identity-blue, $hex);

// Product colors
$c-tl-product-search:       #195292;
$c-tl-product-newsletter:   #3A7ECB;
$c-tl-product-list-builder: #20BEC3;


// ==============================================
// Named Colors
// ==============================================

// Named colors are rarely used in other partials. They establish the standard
// palette to be referenced from other variables in this file.

// Primary + secondary colors
$c-blue:         $c-tl-identity-blue;
$c-blue-dark:    $c-tl-identity-blue-shaded;
$c-green:        $c-library-green-6;
$c-orange:       $c-library-orange-6;
$c-pink:         $c-library-pink-6;
$c-pink-light:   $c-library-pink-3;
$c-purple:       $c-library-violet-3;
$c-purple-dark:  $c-library-violet-4;
$c-red:          $c-library-red-6;
$c-yellow:       $c-library-yellow-5;
$c-yellow-light: $c-library-yellow-0;

// Natural colors
$c-concrete: hsl(220, 13%, 60%); // Light
$c-slate:    hsl(220, 13%, 45%); // Dark


// ==============================================
// UI State Colors
// ==============================================

$c-action:   $c-blue;
$c-cta:      $c-purple-dark;
$c-stimulus: $c-blue-dark;
$c-subtle:   $c-concrete;

// ----------------------------------------------
// Notifications

$c-danger:   $c-red;
$c-help:     $c-purple;
$c-info:     $c-blue;
$c-security: #E0F2FF;
$c-success:  $c-green;
$c-warn:     $c-yellow;

// ----------------------------------------------
// Tone - Default

$c-background: #F0F0F4; // Tint blue +4
$c-border:     #E3E3E7; // Tint blue +4

// ----------------------------------------------
// Tone - Contrast

$c-contrast-background: #505054; // Tint blue +4
$c-contrast-border:     darken($c-contrast-background, 10%);

// ----------------------------------------------
// Tone - Emphasis

$c-emphasis-background: #E8E8E8;
$c-emphasis-border:     #C0C0C0;

// ----------------------------------------------
// Tone - Help

$c-help-background:        $c-purple;
$c-help-background-hover:  darken($c-purple, 4%);
$c-help-background-focus:  darken($c-purple, 4%);
$c-help-background-active: darken($c-purple, 4%);

// ----------------------------------------------
// Tone - Offset

$c-offset-background: #808084; // Tint blue +4
$c-offset-border:     darken($c-offset-background, 10%);

// ----------------------------------------------
// Tone - Priority

$c-priority-background: $c-yellow-light;

// ----------------------------------------------
// Tone - Subtle

$c-subtle-background: #FBFBFB;
$c-subtle-border:     #EBEBEB;


// ==============================================
// Text Colors
// ==============================================

$c-text:        #404048; // Tint blue +8
$c-text-subtle: #888;

$c-text-contrast:        #FFF;
$c-text-contrast-subtle: #C0C0C0;


// ==============================================
// Element Colors
// ==============================================

// ----------------------------------------------
// Button

$c-button:        $c-action;
$c-button-hover:  darken($c-action, 5%);
$c-button-focus:  darken($c-action, 5%);
$c-button-active: darken($c-action, 5%);

$c-button-cta:        $c-cta;
$c-button-cta-hover:  darken($c-cta, 5%);
$c-button-cta-focus:  darken($c-cta, 5%);
$c-button-cta-active: darken($c-cta, 5%);

$c-button-danger:        $c-danger;
$c-button-danger-hover:  darken($c-danger, 5%);
$c-button-danger-focus:  darken($c-danger, 5%);
$c-button-danger-active: darken($c-danger, 5%);

$c-button-disabled: hsl(220, 13%, 90%);
$c-button-disabled-text: #A4A4A8; // Tint blue +4

$c-button-minimal:        white(0.15);
$c-button-minimal-hover:  white(0.1);
$c-button-minimal-focus:  white(0.15);
$c-button-minimal-active: white(0.15);

$c-button-subtle:        $c-subtle;
$c-button-subtle-hover:  darken($c-button-subtle, 5%);
$c-button-subtle-focus:  darken($c-button-subtle, 5%);
$c-button-subtle-active: darken($c-button-subtle, 5%);

// ----------------------------------------------
// Footer

$c-footer-background: $c-tl-identity-blue-extra-light;

// ----------------------------------------------
// Form

$c-input-border:      #C0C0C0;
$c-input-focus:       #B4DEFA;
$c-input-focus-error: $c-library-red-2;
$c-input-placeholder: #A8A8A8;
$c-input-text:        $c-text;

// ----------------------------------------------
// Header

$c-header-background: $c-tl-identity-blue;

// ----------------------------------------------
// Heading

$c-h1: $c-text;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// ----------------------------------------------
// Link

$c-link:        $c-action;
$c-link-hover:  $c-stimulus;
$c-link-focus:  $c-stimulus;
$c-link-active: $c-stimulus;

// ----------------------------------------------
// Nav

$c-nav-link:       transparent;
$c-nav-link-hover: $c-tl-identity-blue-shaded;

// ----------------------------------------------
// Site

$c-site-background: #FFF;

// ----------------------------------------------
// Table

$c-table-background: $c-background;
$c-table-border:     $c-border;

$c-table-hover: $c-yellow-light;

$c-table-zebra-light: #FFF;
$c-table-zebra-dark:  tint($c-table-background, 30%);
