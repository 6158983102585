/**
* Gravity Department - Frontend Toolkit
* http://gravitydept.com
*
* @author     Brendan Falkowski
* @copyright  Gravity Department. All rights reserved.
*/


// ==============================================
// Font Stacks
// ==============================================

$f-stack-sans:    'Lato', 'Helvetica Neue', arial, sans-serif;
$f-stack-serif:   'Times New Roman', serif;
$f-stack-special: 'Lato', 'Helvetica Neue', arial, sans-serif;

$f-stack: $f-stack-sans;


// ==============================================
// Font Sizes
// ==============================================

$f-size-xxxl:  28px;
$f-size-xxl:   24px;
$f-size-xl:    20px;
$f-size-l:     18px;

$f-size:       16px;

$f-size-s:     14px;
$f-size-xs:    13px;
$f-size-xxs:   12px;
$f-size-xxxs:  11px;
$f-size-xxxxs: 10px;


// ==============================================
// Base Measures
// ==============================================

$line-height:   1.5;
$margin-bottom: 1.5em;
